<script setup lang="ts">
import { computed, type PropType, toRaw } from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: true,
  },
  size: {
    type: Number,
    required: false,
    default: 18,
  },
  thickness: {
    type: Number,
    required: false,
    default: 1.5,
  },
  speed: {
    type: Number,
    required: false,
    default: 0.7,
  },
  center: {
    type: Boolean,
    required: false,
    default: false,
  },
  color: {
    type: String,
    required: false,
    default: "currentColor",
  },
  preset: {
    type: String as PropType<"large" | "large-white">,
    required: false,
    default: undefined,
  },
});

let center = false; // eslint-disable-line vue/no-dupe-keys
let color: string; // eslint-disable-line vue/no-dupe-keys
let size: number; // eslint-disable-line vue/no-dupe-keys
let thickness: number; // eslint-disable-line vue/no-dupe-keys
let speed: number; // eslint-disable-line vue/no-dupe-keys

if (props.preset === undefined) {
  ({ center, color, size, thickness, speed } = toRaw(props));
} else {
  center = true;
  color = props.preset === "large" ? "var(--blue)" : "#fff";
  size = 64;
  ({ thickness, speed } = toRaw(props));
}

const spinnerStyle = computed(() => ({
  display: center ? "block" : "inline-block",
  margin: center ? "0 auto" : "0",
}));
</script>

<template>
  <svg
    v-show="show"
    :width="size"
    :height="size"
    :style="spinnerStyle"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    class="spinner"
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          :stroke="color"
          :stroke-width="thickness"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            :dur="`${speed}s`"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.spinner {
  vertical-align: text-bottom;
}
</style>

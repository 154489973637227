<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useStore } from "@/stores";

const store = useStore();
const { scrollbarSize } = storeToRefs(store);

const scrollbarSizeDetectorDiv = ref<HTMLDivElement | null>(null);

function getScrollbarSize() {
  if (scrollbarSizeDetectorDiv.value) {
    scrollbarSize.value =
      scrollbarSizeDetectorDiv.value.offsetWidth -
      scrollbarSizeDetectorDiv.value.clientWidth;
  } else {
    scrollbarSize.value = 0;
  }
}

onMounted(() => {
  if (scrollbarSizeDetectorDiv.value) {
    const observer = new ResizeObserver(getScrollbarSize);
    observer.observe(scrollbarSizeDetectorDiv.value);
  }
});
</script>

<template>
  <div ref="scrollbarSizeDetectorDiv" class="scrollbar-size-detector">
    <div />
  </div>
</template>

<style scoped>
.scrollbar-size-detector {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
}

.scrollbar-size-detector div {
  width: 1px;
  height: 100px;
}
</style>

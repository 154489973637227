<script setup lang="ts">
import { computed, nextTick } from "vue";

const props = defineProps({
  direction: {
    type: String,
    default: "vertical",
  },
  duration: {
    type: Number,
    default: 400,
  },
});

const widthOrHeight = computed(() =>
  props.direction === "vertical" ? "height" : "width"
);

function beforeEnter(el: HTMLElement) {
  el.style[widthOrHeight.value] = "0";
  el.style.transitionDuration = `${props.duration}ms`;
  el.style.transitionProperty = widthOrHeight.value;

  nextTick(() => {
    if (widthOrHeight.value === "height") {
      el.style.height = `${el.scrollHeight}px`;
    } else {
      el.style.width = `${el.scrollWidth}px`;
    }
  });
}

function afterEnter(el: HTMLElement) {
  el.style[widthOrHeight.value] = "";
  el.style.transitionDuration = "";
  el.style.transitionProperty = "";
}

function beforeLeave(el: HTMLElement) {
  if (widthOrHeight.value === "height") {
    el.style.height = `${el.scrollHeight}px`;
  } else {
    el.style.width = `${el.scrollWidth}px`;
  }

  el.style.transitionDuration = `${props.duration}ms`;
  el.style.transitionProperty = widthOrHeight.value;

  setTimeout(() => {
    el.style[widthOrHeight.value] = "0";
  }, 50);
}

function afterLeave(el: HTMLElement) {
  el.style[widthOrHeight.value] = "";
  el.style.transitionDuration = "";
  el.style.transitionProperty = "";
}

const hooks = computed(() => {
  return {
    beforeEnter,
    afterEnter,
    beforeLeave,
    afterLeave,
  };
});
</script>

<template>
  <Transition name="slide" v-bind="$attrs" v-on="hooks">
    <slot />
  </Transition>
</template>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  overflow: hidden;
  transition-timing-function: ease-in-out;
}
</style>

<script setup lang="ts">
import { type PropType, ref, watch } from "vue";
import { type RouteLocationRaw } from "vue-router";
import BaseSpinner from "@/components/BaseSpinner.vue";

const props = defineProps({
  btnStyle: {
    type: String as PropType<
      | "primary"
      | "outline-primary"
      | "success"
      | "outline-success"
      | "transparent"
    >,
    required: false,
    default: "primary",
  },
  size: {
    type: String as PropType<"xs" | "sm" | "lg" | "xl">,
    required: false,
    default: undefined,
  },
  href: {
    type: String,
    required: false,
    default: undefined,
  },
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    required: false,
    default: undefined,
  },
  showSpinner: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const tag = ref("");
const attributeName = ref("");
const attributeValue = ref<string | RouteLocationRaw>();
const cssClasses = ref<string[]>([]);

function configureButton() {
  if (props.href !== undefined) {
    tag.value = "a";
    attributeName.value = "href";
    attributeValue.value = props.href;
  } else if (props.to !== undefined) {
    tag.value = "RouterLink";
    attributeName.value = "to";
    attributeValue.value = props.to;
  } else {
    tag.value = "button";

    if (props.showSpinner) {
      attributeName.value = "disabled";
      attributeValue.value = "disabled";
    } else {
      attributeName.value = "";
      attributeValue.value = undefined;
    }
  }

  cssClasses.value = [`btn-${props.btnStyle}`];

  if (props.btnStyle !== "transparent") {
    cssClasses.value.push("btn");
  }

  if (props.size) {
    cssClasses.value.push(`btn-${props.size}`);
  }
}

configureButton();
watch(props, configureButton);
</script>

<template>
  <Component :is="tag" :[attributeName]="attributeValue" :class="cssClasses">
    <slot />

    <BaseSpinner v-if="showSpinner" />
  </Component>
</template>

<style lang="scss" scoped>
.btn {
  --btn-font-size: 1rem;

  display: inline-block;
  border: 1px solid transparent;
  border-radius: 0.25em;
  padding: 0.67em 1.5em;
  cursor: pointer;
  font-size: var(--btn-font-size);
  font-weight: normal;
  line-height: 1.25;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus-visible {
    outline: 0;
  }

  .spinner {
    position: relative;
    left: 0.25em;
  }
}

.btn,
.btn-transparent {
  &:disabled,
  &.disabled {
    pointer-events: none;
    opacity: 0.65;
  }
}

.btn + .btn {
  margin-left: 1rem;
}

.btn-xs {
  --btn-font-size: 0.7rem;

  padding: 0.22em 0.67em;

  .spinner {
    width: 13px;
    height: 13px;
  }
}

.btn-sm {
  --btn-font-size: 0.875rem;

  .spinner {
    width: 17px;
    height: 17px;
  }
}

.btn-lg {
  --btn-font-size: 1.25rem;

  .spinner {
    width: 24px;
    height: 24px;
  }
}

.btn-xl {
  --btn-font-size: 1.5rem;

  .spinner {
    width: 28px;
    height: 28px;
  }
}

.btn-primary,
.btn-outline-primary {
  border-color: var(--orange);

  &:focus {
    box-shadow: 0 0 0 0.2rem var(--orange-a50);
  }
}

.btn-primary {
  background-color: var(--orange);
  color: #fff;

  &:hover {
    border-color: var(--orange-dark);
    background-color: var(--orange-dark);
    color: #fff;
  }
}

.btn-outline-primary {
  background-color: transparent;
  color: var(--orange);

  &:hover {
    background-color: var(--orange);
    color: #fff;
  }
}

.btn-success,
.btn-outline-success {
  border-color: var(--green);

  &:focus {
    box-shadow: 0 0 0 0.2rem var(--green-a50);
  }
}

.btn-success {
  background-color: var(--green);
  color: #fff;

  &:hover {
    border-color: var(--green-dark);
    background-color: var(--green-dark);
    color: #fff;
  }
}

.btn-outline-success {
  background-color: transparent;
  color: var(--green);

  &:hover {
    background-color: var(--green);
    color: #fff;
  }
}

.btn-transparent {
  display: inline-block;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
</style>

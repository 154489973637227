import "normalize.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
  faCamera,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleExclamation,
  faCirclePlay,
  faDownload,
  faLock,
  faMagnifyingGlass,
  faPause,
  faPencil,
  faPlus,
  faRightFromBracket,
  faVolumeHigh,
  faVolumeXmark,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/vue";
import {
  addResponseHandler,
  type ResponseHandlerParams,
  setGlobalRequestHeader,
} from "@virgodev/bazaar/functions/api";
import Cookies from "js-cookie";
import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import { useStore } from "@/stores";

library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faArrowsRotate);
library.add(faCamera);
library.add(faCheck);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faCircleExclamation);
library.add(faCirclePlay);
library.add(faDownload);
library.add(faLock);
library.add(faMagnifyingGlass);
library.add(faPause);
library.add(faPencil);
library.add(faPlus);
library.add(faRightFromBracket);
library.add(faVolumeHigh);
library.add(faVolumeXmark);
library.add(faXmark);

if (navigator.userAgent.includes("Mac OS X")) {
  document.documentElement.classList.add("is-apple-device");
}

addResponseHandler("all", () => {
  setGlobalRequestHeader("X-CSRFToken", Cookies.get("csrftoken"));
});

addResponseHandler(403, ({ body }: ResponseHandlerParams) => {
  if (body.detail === "You do not have an active subscription.") {
    router.replace({
      name: "my_account",
      query: { no_active_subscription: "true" },
    });
  } else {
    useStore().logOut();

    if (router.currentRoute.value.name !== "login") {
      router.push({
        name: "login",
        query: { next: router.currentRoute.value.fullPath },
      });
    }
  }
});

addResponseHandler(404, ({ body }: { body: any }) => {
  if (
    typeof body === "object" &&
    body.detail === "Student not found." &&
    router.currentRoute.value.name !== "choose_student"
  ) {
    router.push({
      name: "choose_student",
      query: { next: router.currentRoute.value.fullPath },
    });
  }
});

const app = createApp(App);

if (import.meta.env.MODE === "production") {
  Sentry.init({
    app,
    dsn: "https://ff133bd75f3d459d90d3fc4f40e7cee1@errors2.danemco.net/4",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      /Failed to fetch dynamically imported module:/,
      /Unable to preload CSS for \/assets\//,
    ],
  });
}

app.component("FontAwesomeIcon", FontAwesomeIcon);

app.use(createPinia());
app.use(router);

app.mount("#app");

<script setup lang="ts">
import Player from "@vimeo/player";
import api from "@virgodev/bazaar/functions/api";
import { storeToRefs } from "pinia";
import { nextTick, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import getNavIcon from "@/methods/get_nav_icon";
import { useStore } from "@/stores";

const route = useRoute();
const store = useStore();
const { activeStudent, howToUseVideoIds } = storeToRefs(store);

const showModal = ref(false);

let sectionSlugLowercase = "";
let videoTotalSeconds = 0;
const videoSecondsWatched: number[] = [];

function recordView() {
  if (videoTotalSeconds === 0 || videoSecondsWatched.length < 3) {
    return;
  }

  const videoTotalSecondsWatched = Math.min(
    videoSecondsWatched.length,
    videoTotalSeconds
  );

  const percentageWatched = Math.round(
    (videoTotalSecondsWatched / videoTotalSeconds) * 100
  );

  videoTotalSeconds = 0;

  api({
    url: `students/${activeStudent.value?.id}/record_how_to_use_view/`,
    method: "POST",
    json: {
      vimeo_video_id: howToUseVideoIds.value[sectionSlugLowercase],
      percentage_watched: percentageWatched,
    },
    options: {
      keepalive: true,
    },
  });
}

const modalRef = ref<InstanceType<typeof BaseModal> | null>(null);
const vimeoPlayerRef = ref<HTMLDivElement | null>(null);
let vimeoPlayer: Player | undefined;

watch(showModal, () => {
  if (showModal.value) {
    nextTick(async () => {
      if (vimeoPlayerRef.value) {
        sectionSlugLowercase = String(route.params.sectionSlug).toLowerCase();

        vimeoPlayer = new Player(vimeoPlayerRef.value, {
          id: howToUseVideoIds.value[sectionSlugLowercase],
          byline: false,
          pip: false,
          portrait: false,
          title: false,
        });

        videoTotalSeconds = await vimeoPlayer.getDuration();

        vimeoPlayer.on("timeupdate", (event) => {
          const currentSecond = Math.floor(event.seconds);

          if (!videoSecondsWatched.includes(currentSecond)) {
            videoSecondsWatched.push(currentSecond);
          }
        });

        vimeoPlayer.play();

        window.addEventListener("unload", recordView);
      }
    });
  } else {
    recordView();
    window.removeEventListener("unload", recordView);
  }
});

onUnmounted(() => {
  recordView();
  window.removeEventListener("unload", recordView);
});
</script>

<template>
  <BaseButton btn-style="transparent" @click="showModal = true">
    <img :src="getNavIcon('how-to-use')" alt="How to Use" />
  </BaseButton>

  <BaseModal v-if="showModal" ref="modalRef" @modal-closed="showModal = false">
    <template #header>
      <h2>How to Use</h2>
    </template>

    <div ref="vimeoPlayerRef" class="vimeo-player" />
  </BaseModal>
</template>

<style lang="scss" scoped>
.modal,
:deep(.modal-header) {
  background-color: #444;
}

.modal {
  width: 95vw;
  height: min(95dvh, var(--app-div-height));
}

:deep(.modal-header) {
  color: #fff;
}

.vimeo-player {
  height: 100%;

  :deep(iframe) {
    width: 100%;
    height: 100%;
  }
}
</style>
